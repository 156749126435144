import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import {
    FaFacebookF,
    FaTelegramPlane,
    FaWhatsapp,
    FaTwitter,
} from "react-icons/fa";
import dateformat from "dateformat";

import { Layout } from "../components/common";
import { MetaData } from "../components/common/meta";

import config from '../utils/siteConfig';

/**
 * Single post view (/:slug)
 *
 * This file renders a single post and loads all the content.
 *
 */
const Post = ({ data, location }) => {
    const post = data.ghostPost;
    const tags = post.tags;
    let podcastAvailable = false;
    let podcastID = null;
    tags.forEach((tag) => {
        if (tag.name.startsWith("#spotify")){
            podcastAvailable = true;
            podcastID = tag.name.split(":")[1];
        }
    });
    return (
        <>
            <MetaData data={data} location={location} type="article" />
            <Helmet>
                <style type="text/css">{`${post.codeinjection_styles}`}</style>
            </Helmet>
            <Layout slug={post.slug}>
                <div className="container">
                    <article className="content">
                        <div align="center">
                            <h1 className="content-title" id="post-title">{post.title}</h1>
                            <div className="post-date">
                                <p style={{marginBottom: `0.2em` }}>{dateformat(
                                    new Date(post.published_at),
                                    `fullDate`
                                )}{" "}
                                by{" "}
                                <a href="https://finshots.in?utm_source=finshotsMarkets&utm_medium=webArticleDate" target="blank">
                                    Finshots
                                </a></p>
                            </div>
                        </div>
                        
                        <section className="post-full-content">
                            {podcastAvailable ? (
                                <>
                                    <iframe 
                                        src= {`https://open.spotify.com/embed-podcast/episode/` + podcastID}
                                        width="100%" height="232" 
                                        frameBorder="0" 
                                        allowTransparency="true" 
                                        allow="encrypted-media">
                                    </iframe>
                                </>
                            ) : (
                                post.feature_image ? (
                                    <figure className="post-feature-image">
                                        <img
                                            src={post.feature_image}
                                            alt={post.title}
                                        />
                                    </figure>
                                ) : null
                            )}
                            {/* The main post content */}
                            <section
                                className="content-body load-external-scripts"
                                dangerouslySetInnerHTML={{ __html: post.html }}
                            />
                        </section>
                    </article>
                </div>
                <div className="container" align="center">
                    <a
                        href={
                            `https://api.whatsapp.com/send?text=` +
                            encodeURI(post.title + ` ` + config.siteUrl + `/` + post.slug + `/`) +
                            `%3Futm_source%3DwebShare%26utm_medium%3DWhatsApp`
                        }
                        style={{ margin: `10px`, fontSize: `36px` }}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <span title="Send on whatsapp">
                            <FaWhatsapp
                                style={{ fontSize: `40px`, color: `#25d366` }}
                            />
                        </span>
                    </a>
                    <a
                        href={
                            `https://twitter.com/intent/tweet?text=` +
                            encodeURI(
                                post.title +
                                ` ` + ` via @finshots` + ` ` +
                                config.siteUrl + '/' + post.slug + `/` + 
                                `?utm_source=webShare&utm_medium=twitter`
                            )
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ margin: `10px`, fontSize: `36px` }}
                    >
                        <span title="Share on twitter">
                            <FaTwitter
                                style={{ fontSize: `40px`, color: `#1da1f2` }}
                            />
                        </span>
                    </a>
                    <a
                        href={
                            `https://telegram.me/share/url?url=` +
                            config.siteUrl + '/' + post.slug + `/` + 
                            `%3Futm_source%3DwebShare%26utm_medium%3Dtelegram`
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ margin: `10px`, fontSize: `36px` }}
                    >
                        <span title="Send on telegram">
                            <FaTelegramPlane
                                style={{ fontSize: `40px`, color: `#34ACE1` }}
                            />
                        </span>
                    </a>
                    <a
                        href={
                            `http://www.facebook.com/sharer.php?u=` +
                            config.siteUrl + '/' + post.slug + `/` + 
                            `%3Futm_source%3DwebShare%26utm_medium%3Dfacebook`
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ margin: `10px`, fontSize: `36px` }}
                    >
                        <span title="Share on facebook">
                            <FaFacebookF
                                style={{ fontSize: `40px`, color: `#3b5998` }}
                            />
                        </span>
                    </a>

                    <br />
                    <br />
                </div>
            </Layout>
        </>
    );
};

Post.propTypes = {
    data: PropTypes.shape({
        ghostPost: PropTypes.shape({
            codeinjection_styles: PropTypes.object,
            title: PropTypes.string.isRequired,
            html: PropTypes.string.isRequired,
            feature_image: PropTypes.string,
            published_at: PropTypes.string,
            url: PropTypes.string,
            slug: PropTypes.string,
            tags: PropTypes.array,
        }).isRequired,
    }).isRequired,
    location: PropTypes.object.isRequired,
};

export default Post;

export const postQuery = graphql`
    query($slug: String!) {
        ghostPost(slug: { eq: $slug }) {
            ...GhostPostFields
        }
    }
`;
